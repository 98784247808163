import React from "react"
import style from "./divider.mod.scss"

const Divider = ({ text, heading, items, link }) => {
  return (
    <div>
      {heading ||
        (text && (
          <div className={style.divider}>
            {!heading && text && <p>{text}</p>}
            {heading && <h4>{heading}</h4>}
            {link && (
              <a href="https://minthc.co.nz">
                <div className={style.divider__button}>
                  <span>Visit our new website here</span>
                  <SvgIcon />
                </div>
              </a>
            )}
          </div>
        ))}
      {!heading && !text && (
        <div className={style.divider_small}>
          {!heading && text && <p>{text}</p>}
          {heading && <h4>{heading}</h4>}
        </div>
      )}
    </div>
  )
}

export default Divider

const SvgIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      d="M4 12h16m0 0-4-4m4 4-4 4"
    ></path>
  </svg>
);