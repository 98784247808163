import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../../components/container/container"
import Divider from "../../components/divider/divider"
import style from "./thrive.mod.scss"
import blur from "../../assets/images/blur.png"
import { motion } from "framer-motion"

const Thrive = ({ content: { heading, subHeading, content, image } }) => {
  const headingVariants = {
    offscreen: {
      x: -30,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 2,
        delay: 0.2,
      },
    },
  }

  const contentVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        duration: 1.2,
      },
    },
  }

  const blurVariants = {
    offscreen: {
      zIndex: -1,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        duration: 1,
        bounce: 0.1,
        delay: 0.8,
      },
    },
  }

  return (
    <div className={style.thrive}>
      <Container width={"large"} gutters>
        <Divider text={subHeading} link />
        <div className={style.thrive__grid}>
          <motion.div
            variants={headingVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
          >
            <h2>{heading}</h2>
          </motion.div>
          <motion.div
            variants={contentVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
          >
            <div className={style.thrive__content}>
              <div
                className={style.thrive__text}
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {image && (
                <div className={style.thrive__image}>
                  <GatsbyImage alt="" image={image.gatsbyImageData} />
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </Container>

      <div className={style.thrive__blur}>
        <motion.div
          variants={blurVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <img src={blur} alt="" />
        </motion.div>
      </div>
    </div>
  )
}

export default Thrive
